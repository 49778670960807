@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@400;500;600&family=Rock+Salt&display=swap');

* {
  box-sizing: border-box;
}

/* html,
body {
  width: 100%;
  /*  height: 100%;
  margin: 0;
  padding: 0px;
  overflow-x: hidden;
} */

#root {
  --background-black: #282c34;
  --text-light: #f5f5f5;
  --nav-button-highlight: #f5f5f5;
  width: 100%;
  --toastify-color-success: #14b8a6;
}

body {
  margin: 0;
  /* min-height: 100vh; */
  font-family: 'Fredoka', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

a {
  outline: none;
  text-decoration: none;
  color: var(--text-light);
}

.App {
  min-height: 100vh;
  background-color: var(--background-black);
}

/* ************************ */
/* ******** HEADER ******** */
/* ************************ */
header {
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background-color: var(--background-black);
  z-index: 10;
}

.logo-font {
  font-family: 'Rock Salt', cursive;
  font-size: 1.8rem;
  color: var(--text-light);
  text-align: center;
}

#logo-and-burger-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  white-space: nowrap;
}

/* **** Burger **** */
#btn-burger {
  height: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  padding: 0;
  background-color: transparent;
  border-color: transparent;
  outline: none;
}

.burger-line {
  display: block;
  width: 3.75rem;
  background-color: var(--text-light);
  height: 0.4rem;
  border-radius: 0.15rem;
}

.open .burger-line:nth-child(1) {
  animation: top-line 1s forwards ease-in-out;
}

.close .burger-line:nth-child(1) {
  animation: top-line 1s reverse backwards ease-in-out;
}

.open .burger-line:nth-child(2) {
  animation: mid-line 1s forwards ease-in-out;
}

.close .burger-line:nth-child(2) {
  animation: mid-line 1s reverse backwards ease-in-out;
}

.open .burger-line:nth-child(3) {
  animation: bottom-line 2s forwards ease-out;
}

.close .burger-line:nth-child(3) {
  animation: bottom-line 2s reverse ease-in-out;
}

@keyframes top-line {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateZ(0) rotate(0);
  }
  50% {
    transform: translateY(1.25rem);
  }
  80% {
    transform: translateZ(0) rotate(395deg);
  }
  90% {
    transform: translateY(0.8rem);
  }
  100% {
    transform: translateY(1.1rem) rotate(405deg);
  }
}

@keyframes mid-line {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateZ(0) rotate(0);
  }
  20% {
    transform: translateZ(0) rotate(10deg);
  }
  50% {
    transform: translateY(-0.3rem);
  }
  90% {
    transform: translateY(-1.25rem);
  }
  100% {
    transform: translateZ(0) rotate(-405deg);
  }
}

@keyframes bottom-line {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(-0.45rem) rotate(15deg);
  }
  30% {
    transform: translateY(1.875rem) rotate(-10deg);
  }
  50% {
    transform: translateY(0.45rem) rotate(5deg);
  }
  75% {
    transform: translateY(1.25rem);
  }
  100% {
    transform: translateX(-70rem);
  }
}

/* **** Menu (links) **** */
#menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 25rem;
  align-items: flex-start;
  overflow: hidden;
  background: var(--background-black);
  z-index: 5;
  transition: height 1s ease-in-out;
}

#menu.hidden {
  height: 0;
}

.menu-link {
  position: relative;
  display: inline-block;
  margin: 0.8rem 0 0 0.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: var(--text-light);
  border: 0.2rem solid transparent;
  font-size: 1.4rem;
  text-align: center;
}

@keyframes menu-link-before-anim {
  from {
    border: 0.1rem solid var(--nav-button-highlight);
    top: -0.2rem;
    left: -0.2rem;
  }
  to {
    border: 0.1rem solid var(--nav-button-highlight);
    top: 0.1rem;
    left: 0.1rem;
  }
}

@keyframes menu-link-after-anim {
  from {
    border: 0.1rem solid var(--nav-button-highlight);
    right: -0.2rem;
    bottom: -0.2rem;
  }
  to {
    border: 0.1rem solid var(--nav-button-highlight);
    right: 0.1rem;
    bottom: 0.1rem;
  }
}

.menu-link:hover,
.menu-link:focus {
  text-decoration: none;
  color: var(--nav-button-highlight);
  background-color: transparent;
}

.menu-link:hover:before,
.menu-link:focus:before,
.menu-link:hover:after,
.menu-link:focus:after {
  position: absolute;
  width: 100%;
  height: 100%;
  content: '';
  animation-fill-mode: forwards;
  animation-duration: 0.3s;
}

.menu-link:hover:before,
.menu-link:focus:before {
  animation-name: menu-link-before-anim;
}

.menu-link:hover:after,
.menu-link:focus:after {
  animation-name: menu-link-after-anim;
}

/* ************************ */
/* ******** FOOTER ******** */
/* ************************ */
.footer {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  width: 100%;
  height: 100%;
  color: var(--text-light);
  text-decoration: none;
  font-size: 0.8rem;
  padding: 1rem;
}

/* *************************** */
/* ********** PAGES ********** */
/* *************************** */

h1 {
  font-size: 3rem;
  color: var(--text-light);
  margin: 1rem 0;
}

.strong-italic {
  font-weight: bolder;
  font-style: italic;
  text-align: center;
}

.img-resp {
  width: 100vw;
}

.img-vertical-resp {
  height: 40vh;
}

.page-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.page-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.self-center {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-block {
  color: var(--text-light);
  padding: 0.8rem 1.5rem;
  white-space: pre-line;
  font-size: 1.2rem;
}

.strong-text {
  font-weight: bold;
  font-size: 1.5rem;
  /* text-decoration: underline blink; */
}

.page-title {
  position: relative;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin: 1rem 0;
  align-self: center;
}

.page-title::before,
.page-title::after {
  position: absolute;
  width: 100%;
  height: 100%;
  content: '';
  border: 0.1rem solid var(--nav-button-highlight);
}

.page-title::before {
  top: 0.1rem;
  left: 0.1rem;
}

.page-title::after {
  right: 0.1rem;
  bottom: 0.1rem;
}

.section-title {
  font-size: 2rem;
  text-align: center;
}

.divider {
  height: 0.5rem;
  width: 80vw;
  max-width: 30rem;
  background: linear-gradient(
    90deg,
    var(--background-black),
    var(--text-light),
    var(--background-black)
  );
  border-radius: 1rem;
}

.contact-link {
  font-weight: bolder;
  margin-left: 0.5rem;
}

.inline-navlink {
  text-decoration: underline blink;
  margin-left: 0.5rem;
}

/* ******************************* */
/* ******** MEDIA QUERIES ******** */
/* ******************************* */
/* breakpoints 
375 mobile max 767
768 tablet
1280 Laptop
1920 desktop

parfois desktop dès 1024

*/

@media screen and (min-width: 1024px) {
  header {
    display: flex;
    justify-content: space-between;
  }

  #btn-burger {
    display: none;
  }

  #menu {
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    column-gap: 2rem;
    z-index: 0;
    margin-right: 2rem;
    transition: none;
    height: auto;
  }

  .menu-link {
    margin: 0;
    cursor: pointer;
  }

  .menu-link:last-child {
    margin: 0 2rem;
  }

  #menu.hidden {
    height: auto;
  }

  .page-content {
    width: 60vw;
  }

  .img-resp {
    width: 40vw;
    max-width: 1024 px;
  }
  /* .img-vertical-resp {
    height: 40vw;
  } */
}
