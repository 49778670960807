#creeks.animated {
  animation: rotate-in-center 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

#creeks:hover,
#creeks:focus {
  /* animation: rotate-in-center 6s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards; */
  animation: jello-horizontal 0.9s both;
}

@keyframes rotate-in-center {
  0% {
    transform: rotate(-360deg);
    opacity: 0;
  }
  100% {
    transform: rotate(0);
    opacity: 1;
  }
}

@keyframes jello-horizontal {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
